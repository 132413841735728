#fp-nav.fp-right {
    right: 17px;
}
#fp-nav {
    margin-top: -32px;
    top: 50%;
    position: fixed;
    z-index: 100;
}

.second-page-container {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
flex-direction: column;
/* background: yellow; */
/* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2)), url("../assets/zrce-beach.jpg") */
}
#arrow {
    height: 31vmin;
}

.pager-list-item {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    cursor: pointer;
    list-style: none;
    background: white;
    margin-bottom: 1rem;
    transition: transform 0.2s ease-out;
}
.pager-list-item:hover {
    transform:scale(3);
}
.countdown p {
    font-size: 3.5rem;
}
.pager-list-item span {
    box-shadow: 0px 0px 33px 10px rgb(0,0,0,0.5);
    -webkit-box-shadow: 0px 0px 33px 10px rgb(0, 0, 0, 0.5);
}
.active-page {
    cursor: pointer;
}
.pager-list-item.active-page {
    transform: scale(3);
}

/* second screen */

.bubble-container {
    height: 40vh; 
    width: 40vw; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}
/* @media (orientation : landscape),
(min-device-width : 481px) and (orientation : portrait)  */

@media (orientation: portrait) {
    .bubble-container {
        height: 40vh;
        width: 90vw;
    }
}
@media (orientation: portrait) and (min-width: 481px){
    .bubble-container {
        height: 35vh;
        width: 50vw;
    }
}


.bubble-shape {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    border: black 1px solid;
    overflow: hidden;
    position: relative;
    backface-visibility: hidden;
    background: linear-gradient(45deg, black, transparent);
    touch-action: none;
    -ms-touch-action: none;
    transform: translate3d(0, 0, 0);
}

.bubble-shape:hover > .bubble-image {
    transform: scale(1);
    filter: blur(0px);
    backface-visibility: hidden;
}
.bubble-image {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease, filter 0.3s ease;
    transform: scale(1.5);
    filter: blur(7px);
    backface-visibility: hidden;
    /* pointer-events: none; */
}

.novalja-2021 {
    font-size: 72px;
    background: -webkit-linear-gradient(#fff, #333);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* .deg-60 {
    transform: rotate(61deg) translate(251px) rotate(-61deg);

}

.deg-120 {
    transform: rotate(121deg) translate(251px) rotate(-121deg);

}

.deg-180 {
    transform: rotate(181deg) translate(251px) rotate(-181deg);

}

.deg-240 {
    transform: rotate(241deg) translate(251px) rotate(-241deg);

}

.deg-300 {
    transform: rotate(300deg) translate(251px) rotate(-300deg);

} */


/* .deg-60 {
    transform: rotate(61deg) translate(251px) rotate(-61deg);
     display:block;
   position:absolute;
   top:-5%;
   left:-5%;

}

.deg-120 {
    transform: rotate(121deg) translate(251px) rotate(-121deg);
     display:block;
   position:absolute;
   top:40%;
   left:-20%;

}

.deg-180 {
    transform: rotate(181deg) translate(251px) rotate(-181deg);
     display:block;
   position:absolute;
   top:81%;
   left:35%;

}

.deg-240 {
    transform: rotate(241deg) translate(251px) rotate(-241deg);
     display:block;
   position:absolute;
   top:40%;
   left:85%;

}

.deg-300 {
    transform: rotate(300deg) translate(251px) rotate(-300deg);
     display:block;
   position:absolute;
   top:-5%;
   left:70%;

} */


.circle-wrapper {
    width: 631px;
        height: 631px;
        border-radius: 51%;
        /* background: rgba(0,0,0,0.1); */
        position: relative;
        margin: auto;
        /* margin-top:10vh; */
        margin-bottom: 15vh;
}
