.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  /* cursor: pointer; */
  /* transition: transform 0.3s ease-in, box-shadow 0.3s; */
  /* position: relative; */
}
/* .App-logo:hover {
  transform: rotate(180deg) !important;
  animation: 0;
  top: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
  0 10px 10px rgba(0, 0, 0, 0.22);
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 60s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*  Countdown  */
.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice>span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice>p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 3rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 3rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 3rem;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown>p {
  margin: 0;
  margin-bottom: 2px;
}

.show-counter .countdown>span {
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 1rem;
}

.colon {
  font-size: 3rem;
  margin-top: 1.75rem;
}

p, span {
  color: white;
}

h1 {
  font-family: "Brush Script MT", cursive;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
}

h3 {
  font-family: "Brush Script MT", cursive;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
}

p {
  font-family: "Brush Script MT", cursive;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
}

